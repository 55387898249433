export const zhCN_menu = {
	'app.menu.dashboard': 'Dashboard',
	'app.menu.dashboard.workbeach': '工作台',
	'app.menu.dashboard.anlyanis': '分析页',
	'app.menu.dashboard.monitor': '监控页',

	'app.menu.form': '表单页',
	'app.menu.form.basic': '基础表单',
	'app.menu.form.step': '分步表单',
	'app.menu.form.advanced': '高级表单',

	'app.menu.list': '列表页',
	'app.menu.list.search': '搜索列表',
	'app.menu.list.inquire': '查询表格',
	'app.menu.list.standard': '标准列表',
	'app.menu.list.card': '卡片列表',

	'app.detail': '详情页',
	'app.detail.basic': '基础详情页',
	'app.detail.advanced': '高级详情页',

	'app.result': '结果页',
	'app.result.success': '成功页',
	'app.result.failed': '失败页',

	'app.abnormal': '异常页',
	'app.abnormal.403': '403',
	'app.abnormal.404': '404',
	'app.abnormal.500': '500',

	'app.user': '个人页',
	'app.user.center': '个人中心',
	'app.user.settings': '个人设置'
}
